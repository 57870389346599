@import "../partials/variables";
@import "../partials/mixins";
@import "../partials/functions";

#container {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary-bgcolor;
}

button {
  @import url("https://fonts.googleapis.com/css2?family=Work+Sans:display=swap");
  font-weight: 500;
  letter-spacing: 0.05rem;
  position: relative;
  background-color: $colored-text-color;
  cursor: pointer;
  outline: none;
  color: $primary-color;
  padding: fluid-length(0.2, 0.3) fluid-length(0.8, 1.6);
  overflow: hidden;
  border: none;
  border-radius: 0.2rem;
  transition: all 0.3s ease-out;
  font-size: fluid-length(1.2, 1.7);

  &:hover:not(.disabled) {
    cursor: pointer;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.disabled {
  color: $disabled-color;
  background-color: initial;
  border: 1px solid $disabled-color;
  box-shadow: none;
  cursor: initial;

  @include for-tablet-portrait-up {
    border: none;
  }

  &:hover span:nth-child(1) {
    animation: none;
  }
  &:hover span:nth-child(2) {
    animation: none;
  }
  &:hover span:nth-child(3) {
    animation: none;
  }
  &:hover span:nth-child(4) {
    animation: none;
  }
}

.secondary {
  background-color: initial;
  color: $primary-color;

  &:hover:not(.disabled) {
    background-color: initial;
    color: $primary-color;
    box-shadow: none;
  }
}

@keyframes topLine {
  0% {
    top: -100%;
  }
  100% {
    top: 100%;
  }
}

@keyframes bottomLine {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 100%;
  }
}

@keyframes rightLine {
  0% {
    right: -100%;
  }
  100% {
    right: 100%;
  }
}

@keyframes leftLine {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

button:active:not(.disabled) {
  content: "";
  text-align: center;
  background: linear-gradient(
    25deg,
    lighten($primary-button-color, 50%) 0%,
    darken($primary-button-color, 50%) 100%
  );
  color: $primary-button-text-color;
  box-shadow: inset 0 0 10px 2px lighten($primary-button-color, 90%),
    1px 1px 15px 2px darken($primary-button-color, 100%);
  text-shadow: 1px -1px 3px lighten($primary-button-color, 15%),
    -1px 1px 3px darken($primary-button-color, 75%);
  display: block;
  position: relative;
  top: 0;
  right: 0;
  transition: all 0.3s;
}
