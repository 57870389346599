@import "../partials/variables";

.rail {
  transform: translate(0%, 50%);
}

.railSvg {
  position: absolute;
  width: 100%;
  transform: translate(0, -50%);
  height: $rail-height;
}

.railPath {
  fill: $score-bar-color;
}

.railPathActive {
  fill: $score-bar-highlight-color;
}
