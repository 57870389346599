@import "../partials/variables";

.infoList {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 0.4rem;

  li {
    padding-left: 0.9rem;
    &::marker {
      content: "\2022";
      color: $primary-color;
    }
  }
}
