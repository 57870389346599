@use "sass:string";
@import "./functions";
@import "./variables";

// === MIXINS ===
// mui module
@mixin mui($component, $part, $property, $value) {
  .Mui#{$component}-#{$part} {
    #{$property}: $value;
  }
}

// score module
@mixin fill($component, $value) {
  .fill#{$component} {
    fill: $value;
  }
}

// thumb module

@mixin thumb-float($value: left){
  float: $value;
}

@mixin thumb-values($thumb-lvl-long-side){
  $b:border-;
  @include thumb-float;
  #{$b}top: $thumb-lvl-long-side solid transparent;
  #{$b}bottom: $thumb-lvl-long-side solid transparent;
}

@mixin thumb-center-values($thumb-lvl-short-side, $thumb-lvl-long-side, $thumb-color){
  @include thumb-float;
  width: calc($thumb-lvl-short-side * 2);
  height: calc($thumb-lvl-long-side * 2);
  background-color: $thumb-color;
}
@mixin thumb-right-values($thumb-lvl-short-side, $thumb-lvl-long-side, $thumb-color){
  @include thumb-values($thumb-lvl-long-side);
  border-left: $thumb-lvl-short-side solid $thumb-color;
}
@mixin thumb-left-values($thumb-lvl-short-side, $thumb-lvl-long-side, $thumb-color){
  @include thumb-values($thumb-lvl-long-side);
  border-right: $thumb-lvl-short-side solid $thumb-color;
}

@mixin thumb-center($component, $thumb-lvl-short-side, $thumb-lvl-long-side, $thumb-color) {
  .layer#{$component}ThumbCenter {
    @include thumb-center-values($thumb-lvl-short-side, $thumb-lvl-long-side, $thumb-color);
  }
}
@mixin thumb-right($component, $thumb-lvl-short-side, $thumb-lvl-long-side, $thumb-color) {
  .layer#{$component}ThumbRight {
    @include thumb-right-values($thumb-lvl-short-side, $thumb-lvl-long-side, $thumb-color);
  }
}
@mixin thumb-left($component, $thumb-lvl-short-side, $thumb-lvl-long-side, $thumb-color) {
  .layer#{$component}ThumbLeft {
    @include thumb-left-values($thumb-lvl-short-side, $thumb-lvl-long-side, $thumb-color);
  }
}

// === BREAKING POINTS ===
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
