@import "../partials/variables";
@import "../partials/functions";

.progressBar {
  position: absolute;
  width: 100vw;
  max-width: 100%;
  height: 1px;
  top: 58px;
  left: 0;
}

.seperator {
  background-color: $primary-bgcolor;
  width: fluid-length(0.1, 0.5);
}

.colorPrimary {
  background-color: $secondary-color;
}

.barColorPrimary {
  background-color: $colored-text-color;
}
