@import "../partials/variables";

.mark {
  width: 6px;
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: $primary-bgcolor;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: $mark-height;
}
