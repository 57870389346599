@import "./partials/variables";
@import "./partials/mixins";

.MuiInput-root::before {
  border-bottom-color: $primary-color;
}

.MuiInput-root:hover:not(.Mui-disabled):before {
  border-bottom-color: $primary-color;
}

@include mui("Input", "root", "color", $primary-color);
@include mui("Input", "label", "color", $primary-color);
@include mui("Select", "icon", "color", $primary-color);
@include mui("Radio", "root", "color", $primary-color);
@include mui("Checkbox", "icon", "root", $primary-color);
@include mui("FormLabel", "root", "font-family", $font-face);
@include mui("Select", "select", "font-family", $font-face);
@include mui("Input", "input", "font-family", $font-face);

.Mui-error,
MuiFormLabel-asterisk {
  color: $error-color !important;
  &:after {
    border-bottom-color: $error-color !important;
  }
}

.MuiSvgIcon-root,
.MuiInputLabel-root,
.MuiFormControlLabel-label,
.MuiMenuItem-root,
.MuiOutlinedInput-input {
  color: $primary-color;
}
//head with logo, dropdown menus
.MuiPaper-root {
  background-color: $secondary-bgcolor;
}

.MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-notchedOutline:hover:not(.Mui-disabled) {
  border-color: $primary-color !important;
}

.MuiOutlinedInput-root {
  background-color: $secondary-bgcolor;
}

.MuiContainer-root > *:last-child {
  padding-bottom: 2rem;
}
//radio buttons, checklists and dropdown menu buttons
.MuiAppBar-root {
  background-color: $primary-bgcolor;
}

.MuiSnackbar-root > .MuiPaper-root {
  background-color: white;
}

.MuiAlert-action > .MuiButtonBase-root {
  background-color: white;
  box-shadow: none;
}

.MuiAlert-action > .MuiButtonBase-root:hover {
  background-color: white;
  box-shadow: none;
}

.MuiAlert-action > .MuiButtonBase-root > .MuiSvgIcon-root {
  background-color: white;
  color: black;
}

.MuiAlert-icon > .MuiSvgIcon-root {
  background-color: white;
  color: black;
}

.MuiInputLabel-root.Mui-focused {
  color: $primary-color;
}

.MuiFilledInput-root {
  background-color: $form-field-bgcolor;
  border-radius: 4px;
  color: $primary-color;
  font-family: $font-face;
}

.MuiFilledInput-root:hover {
  background-color: $form-field-bgcolor-hover;
}

.MuiSelectIcon {
  background-color: inherit;
}

.MuiTypography-root {
  font-family: $font-face;
}

.MuiRadio-root {
  border-radius: 0;
}
.MuiRadio-root:hover {
  background-color: $primary-bgcolor;
}

.MuiRadio-root.Mui-disabled > span > svg {
  color: grey;
}

.recharts-polar-angle-axis-tick:nth-child(3) {
  transform: translateY(1rem);
}
