@import "../partials/variables";
@import "../partials/mixins";

.smileyContainer {
  position: relative;
  width: calc($smiley-size + $smiley-increase);
  height: calc($smiley-size + $smiley-increase);
}

.smiley {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $smiley-size;
  height: auto;
  transition: all 0.3s ease-out;

  &:hover {
    width: calc($smiley-size + $smiley-increase);
  }
}

.smileyBegoneLeft {
  margin-left: -150vw;
}
