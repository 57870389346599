@import "../partials/variables";
@import "../partials/mixins";

.iconContainer {
  text-align: center;
}

.icon {
  fill: $colored-text-color;
  width: 32px;
  height: 32px;
  @include for-tablet-portrait-up {
    width: 48px;
    height: 48px;
  }
}
