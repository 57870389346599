@import "../partials/variables";
@import "../partials/mixins";
@import "../partials/functions";

.typography {
  color: $primary-color;
}

// Welcome Page
.text {
  font-size: fluid-length(1, 2.4);
  font-weight: 390;
  color: $primary-color;
}

.mainText {
  font-weight: 400;
  font-size: fluid-length(1.2, 2.6);
}

// Additional Characteristics
.centered {
  text-align: center;
}

.bold {
  font-weight: 600 !important;
}

.h1 {
  font-weight: 400;
  font-size: fluid-length(1.2, 2.6);
  text-decoration: underline 0.05em;
  text-decoration-color: $primary-color;
  //border-bottom: .5px solid currentColor;
  //padding-bottom: 4px;
}

// Options for the Text Component
.expression {
  text-align: center;
  transition: text-shadow 0.2s ease-in-out;
  font-size: fluid-length(0.8, 1.8);
  margin: fluid-length(0.3, 0.8);
}

.subHeader {
  font-size: fluid-length(1.8, 2.6);
}

.form {
  font-size: 16px;
  font-weight: 400;
}

.small {
  font-weight: 300;
  font-size: fluid-length(1.2, 1.5);
}

.caption {
  font-size: fluid-length(1, 1.3);
}

.default {
  color: $primary-color;
}

.light {
  color: $secondary-color;
}

.colored {
  color: $colored-text-color;
}
