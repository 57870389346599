@import "../partials/variables";

.container {
  background-color: $box-bgcolor;
  border-radius: 1rem;
  // Note that these values should be dependent on the spacing used in the respective grid container
  padding-bottom: 8px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 8px;
}

.formContainer {
  // Note that these values should be dependent on the spacing used in the respective grid container
  padding-bottom: 8px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 8px;
}
