@import "../partials/functions";
@import "../partials/variables";
@import "../partials/mixins";

.boxShadow {
  filter: drop-shadow(0px 0px $box-shadow-size $layer1-color);
}

// make slider thumb smoothly
.thumb {
  position: absolute;
  transform: translate(-50%, -50%);
  transition: left 0.2s ease-out;
}

// grip slider thumb
.thumbPartContainer {
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
}

// slider thumb center layer 3
@include thumb-center(
  "3",
  $layer3-short-side,
  $layer3-long-side,
  $layer3-color
);
// // slider thumb center layer 2
@include thumb-center(
  "2",
  $layer2-short-side,
  $layer2-long-side,
  $layer2-color
);
// // // slider thumb center layer 1
@include thumb-center(
  "1",
  $layer1-short-side,
  $layer1-long-side,
  $layer1-color
);

// slider thumb right layer 3
@include thumb-right("3", $layer3-short-side, $layer3-long-side, $layer3-color);
// slider thumb right layer 2
@include thumb-right("2", $layer2-short-side, $layer2-long-side, $layer2-color);
// slider thumb right layer 1
@include thumb-right("1", $layer1-short-side, $layer1-long-side, $layer1-color);

// slider thumb right layer 3
@include thumb-left("3", $layer3-short-side, $layer3-long-side, $layer3-color);
// slider thumb right layer 2
@include thumb-left("2", $layer2-short-side, $layer2-long-side, $layer2-color);
// slider thumb right layer 1
@include thumb-left("1", $layer1-short-side, $layer1-long-side, $layer1-color);
