@use "sass:math";
@import "../partials/functions";

// === FONTS ===
// import google font work sans
@font-face {
  font-family: "sans-serif";
  src: url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap)
    format("opentype");
}

// font
$font-face: "Work Sans", sans-serif;

body {
  font-family: $font-face;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// === COLORS ===
$primary-bgcolor: hsl(246°, 100%, 3%);

// text, outlines, form - lines
$primary-color: #ebebeb;
$secondary-color: #999999;
$colored-text-color: #227671;
// box / form background color
$box-bgcolor: rgba(36, 36, 36, 0.4);
$form-field-bgcolor: rgb(36, 36, 36);
$form-field-bgcolor-hover: lighten($form-field-bgcolor, 10%);
$formfield-bgcolor: rgb(36, 36, 36);
// score selection bar
$score-bar-color: hsl(176°, 53%, 30%);
$score-bar-selected-color: hsl(191°, 39%, 55%);
$score-bar-highlight-color: #b0eee8;
// form - selects
$secondary-bgcolor: hsl(208°, 12%, 14%);
// form - errors
$error-color: hsl(358°, 85%, 58%);
// thumb module colors
$layer3-color: $score-bar-selected-color;
$layer2-color: $primary-bgcolor;
$layer1-color: #b0eee8;
// rail module colors
$rail-fill: $layer1-color;
// score colors
$score-color-light: transparentize($score-bar-selected-color, 0.1);
$score-color-medium-light: transparentize($score-color-light, 0.1);
$score-color-medium-dark: transparentize($score-color-medium-light, 0.1);
$score-color-dark: transparentize($score-color-medium-dark, 0.1);
// button colors
$primary-button-color: lighten($score-color-medium-light, 30%);
$primary-button-highlight-color: $score-color-light;
$primary-button-text-color: $primary-bgcolor;
$disabled-color: hsl(0°, 0%, 50%);

// === SIZES ===
// rail mark height
$mark-height: calc(fluid-length(1.25, 1.9) + 4px);
$rail-height: calc(fluid-length(1.25, 1.9) / 0.95);
// smileyscale
$smiley-size: fluid-length(2, 4);
$smiley-increase: 4px;
// thumb module size
$layer3-short-side: 10px;
$layer2-short-side: calc(#{$layer3-short-side} - 2px);
$layer1-short-side: calc(#{$layer2-short-side} - 3px);
// thumb module vertical size
$sqrtvalue: 1.73;
$layer3-long-side: thumb-layer-side(#{$layer3-short-side}, $sqrtvalue);
$layer2-long-side: thumb-layer-side(#{$layer2-short-side}, $sqrtvalue);
$layer1-long-side: thumb-layer-side(#{$layer1-short-side}, $sqrtvalue);
// set box shadow of thumb on layer 1
$box-shadow-size: calc(#{$layer3-short-side} - #{$layer1-short-side});
